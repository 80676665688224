.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 1.2rem;
}

.card {
  width: 350px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .75rem;
}

.cover {
  margin-bottom: .75rem;
  max-height: 3rem;
  max-width: 100%;
  text-align: center;
}

.description {
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}