.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  background: var(--color-dark);
  color: white;
  height: var(--height-header);
}

.logos {
  display: flex;
  align-items: center;
}

.logoLink {
  font-size: 0;
}

.logo[data-logo="mundoestudiante"] {
  height: 2rem;
}

.logo[data-logo="classfy"] {
  height: 1.25rem;
}

.separator {
  margin: 0 1rem;
}