.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }
}
