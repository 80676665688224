body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Roboto', sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --color-primary-green: #8cc63f;
  --color-primary-green-rgb: 140, 198, 63;
  
  --color-primary-green-light: #bdd736;
  --color-primary-green-light-rgb: 189, 215, 54;
  --color-primary-red: #f0513c;
  --color-primary-orange: #f89f41;

  --color-secondary-green: #18ab50;
  --color-secondary-green-rgb: 24, 171, 80;
  --color-secondary-red: #ee2a3e;
  --color-secondary-orange: #ef5938;

  --color-secondary-turquoise: #17ab7d;
  --color-secondary-yellow: #f4e41e;

  --color-dark: #22262e;
  --color-gray: #8a8a8a;
  --color-light: #f2f2f2;

  --height-header: 4rem;
}